<template>
    <div>
      <!-- // -->
       <el-upload
            class="upload-demo"
            ref="upload"
            :headers="{token:token}"
            action="https://mect.zhihanyixing.com/api/file/upload"
            :on-remove="handleRemove"
            :on-change="fileChange"
            :file-list="fileList"
            multiple
            :on-success="successfile"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        </el-upload>
    </div>
</template>
<script>

import DeriveExcelVue from './DeriveExcel.vue';
export default {
    components:{
      DeriveExcelVue
    },
    data() {
      return {
        reFile:[],
        fileList: [],
        token:sessionStorage.getItem('token'),
        fileNum:0,
        loading:false
      };
    },
    methods: {
      submitUpload() {
        if(this.reFile.length === 0){
          this.$message.warning("请选取要上传的文件");
          return;
        }else{
          this.$refs.upload.submit();
        }
      },
      fileChange(file,fileList){
        const lists = fileList;
        if(file.response){
          // 上传后返回结果
          if(file.code === 500){
            lists.length =lists.length-1;
            this.fileList = lists;
            this.$message.warning(file.response.message);
            return;
          }
        }else{
          // 上传前file改变
          if(this.reFile.indexOf(file.name)===-1){
            this.reFile.push(file.name);
          }else{
            if(lists.length ===0)return
              lists.length =lists.length-1;
              this.fileList = lists;
              setTimeout(()=>{
                this.$message.warning(file.name+"文件已存在");
              },0) 
          } 
        }
      },
      handleRemove(file,fileList) {
        const fileName = file.name;
        const idx = this.reFile.indexOf(fileName);
        if(idx>-1){
          this.reFile.splice(idx,1);
        }
         this.fileList = fileList;
      },
      successfile(file){
         this.fileNum++;
         const res = file;
        if(res.code === 500){
         this.$message.warning(res.message);
        }else{
          this.$message.success("文件上传成功");
        }
        if(this.fileNum === this.reFile.length){
          this.reFile = [];
          this.fileList = [];
          this.fileNum = 0;
          return;
        }
      },
    }
    
  }

</script>
<style lang="scss">
    .upload-demo{
      margin: 20px;
    }
  
</style>